const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  suppliersData: [],
  productsData: [],
  jobNumberData: [],
  verifyData: [],
  warehouseData: [],

};

const purchaseMasterData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_PURCHASE":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_PURCHASE":
      return { ...state };
    case "UPDATE_PURCHASE":
      return { ...state };
    case "GET_JOB_NUMBERS_DATA":
      return {
        ...state,
        jobNumberData: action?.jobNumberData,
      };
    case "GET_WAREHOUSE_DATA":
      return {
        ...state,
        warehouseData: action?.warehouseData,
      };
    case 'GET_VERIFY_JOB_DATA':
      return {
        ...state,
        verifyData: action.verifyData,
      }
    case "GET_SUPPLIERS":
      return {
        ...state,
        suppliersData: action.suppliersData,
      };

    case "GET_PRODUCTS":
      return {
        ...state,
        productsData: action.productsData,
      };

    default:
      return { ...state };
  }
};
export default purchaseMasterData;
