const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  jobNumberData: [],
  expensesData: [],
  verifyData: [],
  warehouseData: [],
  productsData: [],
  pmData: [],
};

const deliveryChallanData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_DELIVERY_CHALLAN":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_DELIVERY_CHALLAN":
      return { ...state };
    case "UPDATE_DELIVERY_CHALLAN":
      return { ...state };

    case "GET_JOB_NUMBERS":
      return {
        ...state,
        jobNumberData: action.jobNumberData,
      };
    case "GET_WAREHOUSE":
      return {
        ...state,
        warehouseData: action?.warehouseData,
      };
    case "GET_EXPENSES":
      return {
        ...state,
        expensesData: action.expensesData,

      }
    case "GET_PRODUCTS_DATA":
      return {
        ...state,
        productsData: action.productsData,

      }
    case 'GET_VERIFY_JOB':
      return {
        ...state,
        verifyData: action.verifyData,
      }
    case 'GET_PM_LIST':
      return {
        ...state,
        pmData: action?.pmData
      }

    default:
      return { ...state };
  }
};
export default deliveryChallanData;
