const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData: [],
    followReportData: [],
    sellPersonData: [],
    branchListData: [],
    followUpStatus: []
}

const followUpReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_FOLLOW_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                statusCounts: action.statusCounts
            }

        case 'GET_REPORT_DATA':
            return {
                ...state,
                followReportData: action.followReportData
            }
        case 'GET_PERSON_LIST':
            return {
                ...state,
                sellPersonData: action.sellPersonData
            }
        case 'GET_BRANCH_LIST_FOLLOW':
            return {
                ...state,
                branchListData: action?.branchListData
            }
        case 'GET_FOLLOW_STATUS':
            return {
                ...state,
                followUpStatus: action.followUpStatus
            }
        default:
            return { ...state }
    }
}
export default followUpReducer
