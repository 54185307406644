const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  productCategoryData: [],
  unitData: [],
};

const productCategoryMasterData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_PRODUCTS":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "CREATE_PRODUCTS":
      return { ...state };
    case "UPDATE_PRODUCTS":
      return { ...state };

    case "GET_PRODUCT_CATEGORY":
      return {
        ...state,
        productCategoryData: action.productCategoryData,
      };
    case "IMPORT_EXCEL":
      return { ...state };
    case "GET_UNIT":
      return {
        ...state,
        unitData: action.unitData,
      };

    default:
      return { ...state };
  }
};
export default productCategoryMasterData;
