const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
    }
  
  const productCategoryMasterData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_PRODUCT_CATEGORY':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'CREATE_PRODUCT_CATEGORY':
        return { ...state }
        case 'UPDATE_PRODUCT_CATEGORY':
          return { ...state }
     
      default:
        return { ...state }
    }
  }
  export default productCategoryMasterData
  