const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: []
    }
  
  const unitMasterData = (state = initialState, action) => {
    switch (action.type) {
  
      case 'GET_DATA_UNIT':
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'CREATE_UNIT':
        return { ...state }
        case 'UPDATE_UNIT':
          return { ...state }
     
      default:
        return { ...state }
    }
  }
  export default unitMasterData
  