const initialState = {
  data: []

}

const qrDataReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_QR_CODE':
      return {
        ...state,
        data: action.data,
      }

    default:
      return { ...state }
  }
}
export default qrDataReducer
