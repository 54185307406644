const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData: [],
    totalStatusCount: [],
    sellPersonData: [],

}

const leadReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_LEAD_NEW_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                totalStatusCount: action.totalStatusCount
            }
        case 'GET_SELL_PERSON_LIST':
            return {
                ...state,
                sellPersonData: action.sellPersonData
            }

        default:
            return { ...state }
    }
}
export default leadReportReducer
