const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    cityListData: [],
    statusCounts: [],
    branchListData: [],
    breakDownData: [],
}

const breakDownReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_BREAKDOWN':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                statusCounts: action.statusCounts
            }
        case 'GET_BREAK_REPORT_DATA':
            return {

                breakDownData: action?.breakDownData
            }
        case 'GET_BRANCH_LIST_BREAK':
            return {
                ...state,
                branchListData: action?.branchListData
            }
        default:
            return { ...state }
    }
}
export default breakDownReportReducer
