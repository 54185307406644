const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
    siteFirmData: [],
    firmData: [],
    jobData: [],

}

const jobHistoryReportReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_LEAD_NEW_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
            }
        case 'GET_SITEFIRM_DATA':
            return {
                ...state,
                siteFirmData: action.siteFirmData
            }
        case 'GET_FIRM_DATA_BY_SITE':
            return {
                ...state,
                firmData: action.firmData
            }
        case 'GET_VERIFY_JOB_NO':
            return {
                ...state,
                verifyData: action.verifyData
            }
        case 'GET_JOB_NUMBERS_SUMMARY':
            return {
                ...state,
                jobData: action?.jobData
            }

        default:
            return { ...state }
    }
}
export default jobHistoryReportReducer
