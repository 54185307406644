const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  warehouseData: [],
};

const productInventoryReportData = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PRODUCT_INVENTORY_REPORT_DATA":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params,
      };
    case "GET_WAREHOUSE_LIST":
      return {
        ...state,
        warehouseData: action?.warehouseData,
      };
    default:
      return { ...state };
  }
};
export default productInventoryReportData;
